const ticker = document.querySelector<HTMLElement>('.usp-ticker')
const tickerItems = ticker?.querySelectorAll<HTMLElement>('.usp-ticker__item')

if (tickerItems?.length) {
    let currentTickerPosition = 0

    setInterval(() => {
        let nextTickerItemIndex =
            currentTickerPosition === tickerItems.length - 1
                ? 0
                : currentTickerPosition + 1
        const positionOfTickerItem = tickerItems[nextTickerItemIndex].offsetTop

        ticker?.scrollTo({
            top: positionOfTickerItem,
            behavior: 'smooth',
        })

        currentTickerPosition = nextTickerItemIndex
    }, 3000)
}
